

$white:#fff;
$black:#000;
$black-lite:#232323;
$gray:#f8f8f8;
$gray-light:#dfdfdf;
$green:#62bb74;
$blue:#06213e;
$red:#ed3f1c;
$red-dark:#971d04;
$size:1px;


@import "fonts";
@import "libs/bootstrap/bootstrap";
@import "libs/bootstrap/bootstrap-reboot";
@import "libs/bootstrap/bootstrap-grid";

@import "libs/slick/slick";
@import "libs/slick/slick-theme";
@import "sliders";
@import "libs/font-awesome/font-awesome";
@import "libs/fansybox/jquery.fancybox";
/*@import "libs/owlCarousel/owl.carousel";
@import "libs/owlCarousel/owl.theme.default";
@import "libs/owlCarousel/owl.theme.green";*/
@import "animate";
@import "checkbox";
/*@import "libs/aos/aos";*/

//standart
body{
  color:$black;
  font-family: $GothamPro !important;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1.2;
  background: #f8f8f8;
}

.fancybox-container{
  font-family: $GothamPro !important;
  font-weight: normal;
  font-style: normal;
}
*{
  outline: none;
  &:focus, &:hover, &:active{
    outline: none;
  }
}
.btn.focus, .btn:focus{
  box-shadow: none;
}
#page{
  overflow: hidden;
}
input, textarea{
  outline: none;
  border:none;
  border-radius: 0;
}
label{
  margin-bottom: 0;
}
ul{
  padding: 0;
  margin: 0;
  li{
    list-style: none;
  }
}
a{
  transition: all 0.5s ease;
  &:hover, &:focus{
    outline: none;
    text-decoration: none;
    transition: all 0.5s ease;
  }
}
img{
  max-width: 100%;
}
.clear{
  clear:both;
}

strong{
  font-weight: bold;
}
h1,.h1{
  font-weight: bold;
  color:$white;
  font-size: 72px;
  line-height: 1.2;
  margin-bottom: 24px;
  @media (max-width: 991px) {
    font-size: 26px;
  }
  &.header-h1{
    margin-bottom: 34px;
  }
}
h2,.h2{
  font-size: 48px;
  font-weight: 900;
  line-height: 48px;
  color:$black-lite;
  text-transform: uppercase;

  @media (max-width:575px) {
    text-align: center;

  }
  @media (max-width:991px) {
    font-size: 31px;
    line-height:1.2 !important;
  }

}
.h2-name{
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  color: $gray-light;
  margin-bottom: 18px;
}
h3,.h3{
  font-size: 24px;
  line-height: 36px;
  font-weight: 900;
  text-transform: uppercase;

  @media (max-width:991px) {
    font-size:20px;
    line-height: 30px;
  }
}
h4, .h4{
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 14px;
  @media (max-width:991px) {
    font-size: 14px;
  }
}

.container{
  @media (max-width:767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.bgGray{
  background: #f8f8f8;
}
.color-white{
  color:$white;
}
.color-black{
  color:$black;
}
.color-blackLite{
  color:$black-lite;
}
.color-green{
  color:$green;
}
.color-gray{
  color:$gray;
}
.color-grayLite{
  color:$gray-light;
}

.btn{
  display: block;
  width: 270px;
  height: 60px;
  line-height: 61px;
  background: $green;
  font-size: 16px;
  font-weight: 900;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease;
  &:hover{
    transition: all 0.5s ease;
    background: #499759;
    color:$white;
  }
  @media (max-width:424px) {
    width: 100%;
    display: inline-block;
  }
}


.br-767{
  @media (max-width:767px) {
    display: block;
  }
}
.br-992{
  @media (min-width:992px) {
    display: block;
  }
}
.br-1200{
  @media (min-width:1200px) {
    display: block;
  }
}
.br-1199{
  @media (max-width:1199px) {
    display: block;
  }
}
//////
.header{
  background: url("../images/bg-header.jpg") no-repeat center;
  background-size: cover;
  padding-top: 15px;
  padding-bottom: 90px;

  .header-advantages{
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top:1px solid rgba($white,0.24);
    border-bottom:1px solid rgba($white,0.24);
    margin-bottom: 40px;

    .header-advantages__item{
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      flex: 0 0 25%;
      padding: 0 15px;
      .icon{
        flex:0 0 70px;
      }
      .text{
        &__title{
          font-size: 16px;
          line-height: 1.2;
          color:$white;
        }
        &__detail{
          font-size: 30px;
          line-height: 1.2;
          color:$white;
          text-transform: uppercase;
        }
      }
      &:before{
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: rgba($white, 0.24);
        left:-3px;
      }
      &.header-advantages__item1{
        padding: 0 15px 0 0;
        &:before{
          opacity: 0;
        }
      }
    }
    @media (max-width:599px){
      .header-advantages__item {
        &:before {
          opacity: 0;
        }
      }
    }
    @media (max-width:991px) {
      margin-bottom: 92px;
      .slick-dots{
        bottom: -57px;
      }
      .header-advantages__item{
        &.header-advantages__item3{
          &:before{
            opacity: 0;
          }
        }
      }
    }
    @media (min-width:992px) and (max-width: 1199px){
      display: block !important;
      .slick-slide>div{
        display: flex !important;
      }
    }
    @media (max-width:1199px){
      .slick-dots{
        bottom: -57px;
      }
     .header-advantages__item{
       //display: block;
       text-align: center;
       flex-wrap: wrap;
       .icon{
         width: 70px;
         margin:0 auto 15px;
         img{
           margin: auto;
           height: 45px;
         }
       }
       .text{
         flex:0 0 100%;
       }

     }
    }

  }
  @media (max-width:767px) {
    position: relative;
    padding-top: 13px;
    &:before{
      content:"";
      position: absolute;
      width: 100%;
      padding-top: 15px;
      height: 69px;
      background: #161228;
      top:0;
    }
  }
  @media (max-width:550px) {
    background-position-x: 63%;
    .h2-name{
      max-width: 200px;
    }
  }
  @media (max-width:991px){
    padding-bottom: 40px;

    .btn{
      margin: auto;
    }
  }
}
.header-top{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  @media (max-width:767px) {
    position: relative;
    z-index: 1;
  }
  @media (max-width:991px){
    margin-bottom:40px;
   }
}
.header-logo{
  flex: 0 0 30%;
  .logo-320{
    display: none;
  }
  .logo-425{
    display: block;
  }
  @media (max-width:599px) {
    .logo-320{
      display: block;
    }
    .logo-425{
      display: none;
    }
  }

}
.header-contacts{
  @media (max-width:767px) {
    &.contacts-block{
      width: 70%;
      .contact{
        line-height: 20px;
        &__phone{
          font-size: 14px;
          font-weight: 500;
        }
        &__mail{
          font-size: 14px;
          font-weight:normal;
        }
      }
      .contact-dp{
        flex:0 0 13px;
        line-height: 20px;
        padding-left: 10px;
        margin-left: 10px;
        position: relative;
        &:before{
          content:"";
          position: absolute;
          width: 1px;
          height: 100%;
          background: rgba(#8e909d, 0.24);
          left: 0;

        }
        &__down{
          display: block;
          width: 13px;
          margin-bottom: 4px;
        }
        &__print{

          margin-top: 4px;
        }
      }
    }

  }
}
.contacts-block{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  text-align: right;
  .contact{
    line-height: 30px;
    &__phone{
      font-size: 24px;
      font-weight: 500;
      color:$white;
      &:hover{
        color:$green;
      }
    }
    &__mail{
      font-size: 18px;
      font-weight:normal;
      color:$green;
      border-bottom:1px solid $green;
      &:hover{
        color:$white;
        border-bottom:1px solid $white;
      }
    }
  }
  .contact-dp{
    flex:0 0 38px;
    line-height: 30px;
    margin-left: 22px;
    position: relative;
    &:before{
      content:"";
      position: absolute;
      width: 1px;
      height: 100%;
      background: rgba(#8e909d, 0.24);
      left: 0;

    }
    &__down{
      display: block;
    }
    &__print{

      margin-top: 4px;
    }
  }
}

//kd ключевые достижения
.kd{
  padding-top: 50px;
  h2{
    display: inline-block;
    margin-right: 20px;
    margin-left: 26px;
    text-align: left;
  }
  .dop{
    display: inline-block;
    font-size: 120px;
    font-weight: 900;
    color:$green;
    span{
      font-size: 49px;
      transform: rotate(-90deg);
      display: inline-block;
      position: relative;
      top: -24px;
      left: -58px;
    }
  }

  @media (max-width:767px) {
    .dop{
      font-size: 80px;
      span{
        font-size: 34px;
        top: -15px;
        left:-44px;
      }
    }
  }
  @media (min-width:768px) and (max-width: 991px) {

    .dop{
      font-size: 98px;
      line-height: 83px;
      span{
        font-size: 40px;
        top: -19px;
        left: -44px;
      }
    }
  }
  @media (max-width:991px) {
    padding-top: 33px;
    h2{
      margin-left: 0;
    }
  }
}
.kd-items{
  display: flex;
  flex-wrap: wrap;
  margin-top: 21px;
  .kd-item{
    flex:0 0 33.333%;
    position: relative;
    height: 310px;
    overflow: hidden;
    &:before{
      content:"";
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .kd-item__img{
      width: 100%;
      height: 100%;
      transform: scale(1);
      background-size: cover;
      transition: all 0.5s ease;
    }
    .kd-item__detail{
      position: absolute;
      width: 100%;
      top:110px;
      padding: 0 28px;
      z-index: 2;
      transition: all 0.5s ease;
      .icon{
        margin-bottom: 17px;
      }
      .text{
        font-size: 24px;
        line-height: 1.2;
        font-weight: 900;
        color:$white;
        margin-bottom: 12px;
        height: 84px;
      }
      .link{
        font-size: 16px;
        line-height: 1.2;
        color:$white;
        border-bottom: 1px solid $white;
        transition: all 0.5s ease;
        transform: translateY(50px);
        display: inline-block;
      }
    }
    &:nth-last-child(-n+3){
      .kd-item__detail{
        top:80px;
        .text{
          height: 112px;
        }
      }
    }


  }
  @media (max-width:767px) {
    .kd-item{
      flex:0 0 100%;
    }
  }
  @media (min-width:768px) and (max-width: 991px) {
    .kd-item{
      flex:0 0 50%;
    }
  }
  @media (max-width: 1199px) {
    .kd-item{

      .kd-item__detail{
        top:59px;
        .text{
          font-size: 18px;
          font-weight: 500;
          height: 92px;
          max-width: 253px;
        }
        .link{
          transform: translateY(0px);
        }
      }
      &:nth-last-child(-n+3){
        .kd-item__detail{
          top:57px;
          .text{
            height: 92px;
          }
        }
      }

    }
  }
  @media (max-width:1023px) {
    .kd-item{
      &:before{
        background: transparent !important;
      }
    }
  }
  @media (min-width:1024px) and (max-width: 1199px) {
    .kd-item{

      &.kd-item1{
        &:before{
          background: #62bb74;
        }
      }
      &.kd-item2{
        &:before{
          background: #5aac6a;
        }
      }
      &.kd-item3{
        &:before{
          background: #519a60;
        }
      }
      &.kd-item4{
        &:before{
          background: #519a60;
        }
      }
      &.kd-item5{
        &:before{
          background: #62bb74;
        }
      }
      &.kd-item6{
        &:before{
          background: #5aac6a;
        }
      }
      .kd-item__detail{
        .link{
          transform: translateY(63px);
        }
      }

      &.active{
        &:before{
          transition: all 0.5s ease;
          transform:translateY(-315px);
        }
        .kd-item__detail{
          .link{
            transform: translateY(0px);
          }
        }
      }

    }
  }
  @media (min-width:1200px) {
    .kd-item{
      &.kd-item1{
        &:before{
          background: #62bb74;
        }
      }
      &.kd-item2{
        &:before{
          background: #5aac6a;
        }
      }
      &.kd-item3{
        &:before{
          background: #519a60;
        }
      }
      &.kd-item4{
        &:before{
          background: #519a60;
        }
      }
      &.kd-item5{
        &:before{
          background: #62bb74;
        }
      }
      &.kd-item6{
        &:before{
          background: #5aac6a;
        }
      }
      &:hover{
        transition: all 0.5s ease;
        &:before{
          transition: all 0.5s ease;
          transform:translateY(-315px);
        }
        .kd-item__detail{
          transition: all 0.5s ease;
          transform: translateY(-35px);
          .link{
            transition: all 0.5s ease;
            transform: translateY(0px);
          }
        }
        .kd-item__img{
          transform: scale(1.1);
          transition: all 0.5s ease;
        }
      }
    }
  }
}

//forma
.app-form{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 51px;
  .app-form__h3{
    margin-bottom: 12px;
    text-align: left;
    flex:0 0 100%;
  }
  .app-form__fieldset{
    //width: calc((100% - 350px) / 3);
    flex:0 0 270px;
    position: relative;
    margin-bottom: 24px;
    &:before{
      content: "";
      position: absolute;
      left: 20px;
      width: 15px;
      height: 15px;
      z-index: 1;
    }
    input{
      width: 100%;
      height: 60px;
      background: $white;
      color:$blue;
      font-size: 16px;
      line-height: 60px;
      padding: 0 14px 0 43px;
      position: relative;
    }
    /*&:nth-child(3){
      margin: 0 20px 20px;
    }*/
    &.app-form__fieldset-name{
     &:before{
       background: url("../images/icon-name.png") no-repeat;
       top:23px;
     }
    }
    &.app-form__fieldset-phone{
      &:before{
        background: url("../images/icon-tel.png") no-repeat;
        top:24px;
      }
    }
    &.app-form__fieldset-email{
      &:before{
        background: url("../images/icon-mail.png") no-repeat;
        top:25px;
      }
    }
  }
  .app-form__fieldset-submit{
    //margin-left: 40px;
    margin-bottom: 24px;
    .btn{
      background: $green;
      border:2px solid $green;
      color:$white;
      line-height: 56px;
      transition: all 0.5s ease;
      padding: 0;
      &:hover{
        transition: all 0.5s ease;
        background: #499759;
        color:$white;
        border:2px solid #499759;
      }
    }
  }
  .app-form__personal{
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    color:$black-lite;
    a{
      color:$black-lite;
      border-bottom: 1px solid $black-lite;

    }
  }
  .app-form__callback{
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    color:$black-lite;
    text-transform: uppercase;
    text-align: right;
    a{
      display: block;
      font-size: 16px;
      font-weight: 900;
      color:$black-lite;
    }
  }

  @media (max-width:425px) {
    .app-form__callback{
      a{
        display: block !important;
      }
    }
    .app-form__fieldset-submit{
      width: 100%;
    }
  }
  @media (max-width:767px) {
    .app-form__h2{
      font-size: 31px !important;
      text-align: center;
      strong{
        display: block;
      }
    }
    .app-form__fieldset{
      flex:0 0 100%;
      margin-bottom: 10px;
      &:nth-child(3){
        margin: 0 0 10px;
      }
    }
    .app-form__personal{
      order:4;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .app-form__fieldset-submit{
      order:5;
      margin-bottom: 20px;
    }
    .app-form__callback{
      order:6;
      margin: 0 auto;
      a{
        display: inline-block;
      }
    }
  }
  @media (min-width:768px) and (max-width:991px) {
    .app-form__fieldset{
      flex:0 0 31.3%;
      margin-bottom: 20px;
      &:nth-child(3){
        margin: 0 20px 20px;
      }
    }
    .app-form__personal{
      margin-top: 0;
      width: calc(100% - 300px);
      order:4;
    }
    .app-form__fieldset-submit{
      margin: 0px;
      flex:0 0 270px;
      order:5;
      .btn{
        margin: 0;
      }
    }

    .app-form__callback{
      order:6;
      margin: 20px auto 0 ;
      a{
        display: inline-block;
      }
    }
  }
  @media (min-width:992px) and (max-width:1199px) {
    .app-form__fieldset{
      flex:0 0 23.5%;
      margin-bottom: 20px;
      margin-left: 5px;
      margin-right: 5px;
      &:nth-child(3){
        margin: 0 5px 20px;
      }
      &:nth-child(2){
        margin-left: 0 !important;
      }
      &:nth-child(5){
        margin-right: 0 !important;
      }

    }
    .app-form__personal{
      margin-top: 0;
      width: calc(100% - 300px);
      order:5;
    }
    .app-form__fieldset-submit{
      margin: 0px 5px 20px;
      //flex:0 0 31.3%;
      order:4;
      .btn{
        margin: 0;
      }
    }

    .app-form__callback{
      //flex:0 0 23.5%;
      order:6;
      margin: 20px auto 0 ;
      a{
        display: block;
      }
    }
  }
  @media (max-width:1199px) {
    .app-form__callback{
      text-align: center;
    }
  }
}
.app-form1{
  padding-bottom: 80px;
  @media (max-width:991px){
    padding-bottom: 38px;
  }
  @media (min-width:992px) and (max-width: 1199px) {
    .app-form__callback{
      flex:0 0 23.5%;
      order:6;
      margin: 0;
      a{
        display: block;
      }
    }
  }
}
.app-form2{
  padding-bottom: 66px;
  .app-form__fieldset{
    input{
      background: rgba($white, 0.15);
      color:$white;
    }
    ::-webkit-input-placeholder {color:$white;}
    ::-moz-placeholder          {color:$white;}/* Firefox 19+ */
    :-moz-placeholder           {color:$white;}/* Firefox 18- */
    :-ms-input-placeholder      {color:$white;}
    &.app-form__fieldset-name{
      &:before{
        background: url("../images/icon-nameWhite.png") no-repeat;
        top:23px;
      }
    }
    &.app-form__fieldset-phone{
      &:before{
        background: url("../images/icon-telWhite.png") no-repeat;
        top:24px;
      }
    }
    &.app-form__fieldset-email{
      &:before{
        background: url("../images/icon-mailWhite.png") no-repeat;
        top:25px;
      }
    }
  }
  .app-form__personal{
    color: #575462;
    a{
      color: #575462;
      border-bottom: 1px solid #575462;
    }
  }
  .app-form__callback{
    color: #575462;
    a{
      color: #575462;
    }
  }
  @media (max-width:991px) {
    padding-top: 33px;
    padding-bottom: 38px;
  }
  @media (min-width:992px) and (max-width: 1199px) {
  .app-form__callback{
    flex:0 0 23.5%;
    order:6;
    margin: 0;
    a{
      display: block;
    }
  }
  }
}
.app-form3{
  padding-top: 0;
  padding-bottom: 63px;
  .app-form__h3 {
    margin-bottom: 33px;
  }
  .app-form__fieldset{
    flex:0 0 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    input{
      background: rgba($white, 0.15);
      color:$white;
    }
    ::-webkit-input-placeholder {color:$white;}
    ::-moz-placeholder          {color:$white;}/* Firefox 19+ */
    :-moz-placeholder           {color:$white;}/* Firefox 18- */
    :-ms-input-placeholder      {color:$white;}
    &.app-form__fieldset-name{
      &:before{
        background: url("../images/icon-nameWhite.png") no-repeat;
        top:23px;
      }
    }
    &.app-form__fieldset-phone{
      &:before{
        background: url("../images/icon-telWhite.png") no-repeat;
        top:24px;
      }
    }
    &.app-form__fieldset-email{
      &:before{
        background: url("../images/icon-mailWhite.png") no-repeat;
        top:25px;
      }
    }
  }
  .app-form__personal{
    color: #8e909d;
    order:4;
    margin-top: 20px;
    margin-bottom: 28px;
    width: 100%;
    a{
      color: #8e909d;
      border-bottom: 1px solid #8e909d;

    }
  }
  .app-form__fieldset-submit{
    order:5;
    margin-bottom: 19px;
  }
  .app-form__callback{
    order:6;
    color: $white;
    margin: auto;
    a{
      display: inline;
      color: $white;
    }
  }
  @media (max-width:991px) {
    padding-bottom: 38px;
  }
}



//block-form2
.block-form2{
  background: #161228;
}

//competencies
.competencies{
  background: url("../images/bg-competencies.jpg") no-repeat center;
  background-size: cover;
  padding-top: 74px;

  .app-form2{
    padding-bottom: 57px;
    .app-form__personal{
      color: rgba(142, 144, 157, 255);
      a{
        color: rgba(142, 144, 157, 255);
      }
    }
    .app-form__callback{
      color: rgba(142, 144, 157, 255);
      a{
        color: rgba(142, 144, 157, 255);
      }
    }
  }
  @media (max-width:991px) {
    padding-top: 33px;
    .app-form2{
      padding-bottom: 38px;
    }
  }
  @media (max-width: 1199px) {


  }
}
.competencies-items{
  display: flex;
  flex-wrap: wrap;
  margin-top: 41px;

  .competencies-item{
    //flex: 0 0 20%;
    width: calc(100% / 5);
    padding: 26px 23px 26px 31px;
    .title{
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      color: $black-lite;
      margin-bottom: 25px;
      height: 56px;
    }
    .list{
      font-size: 14px;
      line-height: 18px;
      font-weight: 900;
      color:$white;
      text-transform: uppercase;
      li{
        padding-left: 15px;
        margin-bottom: 19px;
        position: relative;

        &:before{
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $white;
          left: 0;
          top:6.7px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &.competencies-item1{
      background: $green;
    }
    &.competencies-item2{
      background: #59b26b;
    }
    &.competencies-item3{
      background: #4fa661;
    }
    &.competencies-item4{
      background: #469857;
    }
    &.competencies-item5{
      background: #3d8b4d;
    }
  }
  @media (max-width:991px) {
    margin-top: 33px;
  }
  @media (max-width:424px) {
    .competencies-item{
      .title{
        font-size: 21px;
      }
    }
  }
  @media (max-width:1199px) {
    .competencies-item{
      flex: 0 0 100%;
      padding: 26px 23px 0 31px;

      .title{
        position: relative;
        height: auto;
        &:after{
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          background: url("../images/icon-plusBlack.png") no-repeat center;
          right: 0;
          top:6px;
        }
      }

      &.active{
        padding: 26px 23px 26px 31px;
        .title{
          &:after{
            background: url("../images/icon-minusBlack.png") no-repeat center;
          }
        }
      }
    }
  }
}

//portfolio-item
.portfolio{
  padding-top: 60px;
  padding-bottom: 60px;
  h2{
    margin-bottom: 70px;
  }

  @media (max-width:991px) {
    padding-top: 33px;
    padding-bottom: 38px;
    h2{
      margin-bottom: 33px;
    }
  }
  .row{
    .col-12{
      display: none;
      //opacity: 0;
      &.active{
        display: block;
       // opacity: 1;
      }
    }
  }
}
.portfolio-item{
  display: block;
  box-shadow: 0px 5px 35px rgba(0,0,0,0.14);
  margin-bottom: 30px;
  transition: all 0.5s ease;
  .portfolio-item__img{
    transition: all 0.5s ease;
    overflow: hidden;
    img{
      transition: all 0.5s ease;
    }
  }
  .portfolio-item__detail{
    background: $white;
    padding:26px 30px 30px;
    .title{
      font-size: 24px;
      line-height: 1.2;
      font-weight: 500;
      color:$black-lite;
      height: 54px;
      overflow-y:hidden ;
      margin-bottom: 16px;
      transition: all 0.5s ease;
    }
    .bottom{
      .year{
        display: inline-block;
        width: calc(100% - 146px);
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        font-weight: bold;
        color:#d2d2d2;
      }
      .more{
        display: inline-block;
        width: 140px;
        height: 40px;
        background: $green;
        font-size: 14px;
        line-height: 40px;
        font-weight: bold;
        color:$white;
        text-align: center;
        transition: all 0.5s ease;
      }
    }
  }

  &:hover{
    transition: all 0.5s ease;
    .portfolio-item__img{
      transition: all 0.5s ease;
      img{
        transition: all 0.5s ease;
        transform: scale(1.05);
      }

    }
    .portfolio-item__detail{
      .title{
        transition: all 0.5s ease;
        color:$green;
      }
    }
    .bottom{
      .more{
        transition: all 0.5s ease;
        background: #499759;
      }
    }
  }
  @media (max-width:575px) {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    .portfolio-item__detail{
      .title{
        font-size: 18px;
        height: 45px;
      }
    }
  }
  @media (min-width:576px) and (max-width: 767px) {
    .portfolio-item__detail{
      .title{
        font-size: 15px;
        height: 38px;
        margin-bottom: 13px;
      }
      .bottom{
        .year{
          width: calc(100% - 126px);
        }
        .more{
          width: 120px;
        }
      }
    }
  }
  @media (min-width:768px) and (max-width: 991px) {
    .portfolio-item__detail{
      .title{
        font-size: 18px;
        height: 45px;
        margin-bottom: 13px;
      }
    }
  }
  @media (min-width:992px) and (max-width: 1199px) {
    .portfolio-item__detail{
      .title{
        font-size: 18px;
        height: 45px;
      }
    }
  }
}
.button-more{
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color:$green;
  position: relative;
  padding-top: 55px;
  margin-top: 10px;
  text-align: center;
  transition: all 0.5s ease;
  &:before{
    content:"";
    position: absolute;
    width: 36px;
    height: 36px;
    background: $green url("../images/icon-plus.png") no-repeat center;
    border-radius: 50%;
    top:0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 0.5s ease;
  }
  &:hover{
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: underline;

    &:before{
      transition: all 0.5s ease;
      transform: scale(1.1);
      box-shadow: 0px 0px 15px 5px rgba($green,0.35);
    }
  }
}

//certificates
.certificates{
  padding-top: 55px;
  padding-bottom: 60px;
  h2{
    margin-bottom: 42px;
  }
  @media (max-width:991px) {
    padding-top: 33px;
    padding-bottom: 36px;
    h2{
      margin-bottom: 0;
    }
  }
  @media (max-width:1199px) {
    .certificates-more{
      display: none;
    }
  }
  @media (min-width:1200px) {
    .row{
      .col-12{
        display: none;
        &.active{
          display: flex !important;
        }
      }
    }
  }
}
.certificates-items{
  align-items: center;
  .certificates-item{
    display: flex;
    position: relative;
    height: 310px;
    transition: all 0.5s ease;

    .img{
      margin: auto;
      position: relative;
      transition: all 0.5s ease;
      img{
        margin: auto;
      }
      &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../images/icon-lupaBlack.png") no-repeat center;
        z-index: 1;
        opacity: 1;
        transition: all 0.5s ease;
      }
      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../images/icon-lupaHover.png") no-repeat center;
        z-index: 1;
        top:0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: all 0.5s ease;
      }
    }
    &:before{
      content:"";
      position: absolute;
      width:1px;
      height: 100%;
      background: #f0f0f0;
      left: 0;
    }
    &:nth-child(4n+1){
      &:before{
        opacity: 0;
      }
      &:after{
        content: "";
        position: absolute;
        width: calc(100% * 4);
        height:1px;
        background: #f0f0f0;

      }
    }
    &:nth-child(-n+4){
      &:after{
        opacity: 0;
      }
    }
    &:hover{
      transition: all 0.5s ease;
      .img{
        transform: scale(1.07);
        &:before{
         opacity: 0;
        }
        &:after{
          opacity: 1;
        }
      }
    }
  }
  @media (min-width:499px) and (max-width: 991px) {
    .certificates-item{
      flex: 0 0 50%;
    }
  }
  @media (min-width:992px) and (max-width: 1199px) {
    .certificates-item{
      flex: 0 0 31.3%;
    }
  }
  @media (max-width:1199px) {
    margin-bottom: 17px !important;
    .slick-arrow{
      text-align: center;
      bottom: -6px;
      top:auto;
      &.prevArrow{
        display: inline-block;
        width: 42px;
        height: 15px;
        vertical-align: middle;
        //right:415px;
        left: 20%;
        background: url("../images/carousel_arrows.png") left center no-repeat;
      }
      &.nextArrow{
        display: inline-block;
        width: 42px;
        height: 15px;
        vertical-align: middle;
        background: url("../images/carousel_arrows.png") left -50px center no-repeat;
        right:20%;
        left: auto;
      }


    }
    .slick-dots{
      top: auto;
      bottom: -18px;
      left: 0;
      right: -41px;
      margin: auto;
      counter-reset: a;
      position: absolute;
      margin-top: 15px;
      width: 60px;

      &:before{
        content:"  /";
        display: inline-block;
        vertical-align: middle;
        font-size: 24px;
        position: absolute;
        left: 0px;
        top: 4px;

      }
      &:after{
        font-size: 24px;
        line-height: 36px;
        font-weight: 800;
        color:$green;
        content: counter(slides-num);
        display: inline-block;
        vertical-align: middle;
      }
      li{
        position: absolute;
        right: 60px;
        counter-increment: slides-num;
        pointer-events: auto;
        button {
          display: none !important;
        }
        &.slick-active {
          &:before {
            content: counter(slides-num);
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
            line-height: 36px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      &:after{
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        color:$green;
        content: counter(slides-num);
        display: inline-block;
        vertical-align: middle;
      }
    }
    .slick-slide{
      >div{
        display: flex !important;
        align-items: center;
        height: 310px;
      }
    }
    .certificates-item{
      display: flex !important;
      &:before{
        height: 50%;
        top:25%;
      }
      &:hover{
        .img{
          transform: scale(1);
        }
      }
    }
  }

}


.footer{
  background: url("../images/bg-footer.jpg") no-repeat center;
  background-size: cover;
  padding-top: 74px;
  padding-bottom: 32px;
  h1{
    margin-bottom: 17px;
  }
  .app-form3{
    .app-form__fieldset-submit{
      margin-bottom: 17px !important;
    }
  }
  @media (max-width:550px) {
    background-position-x: 63%;
    .h2-name{
      max-width: 200px;
    }
  }
  @media (max-width:991px) {
    padding-top: 35px;
    .app-form3{
      .app-form__h3{
        font-size: 15px;
        margin-bottom: 24px;
      }
    }
  }
}
.footer-bottom{
  @media (max-width:991px) {
    .footer-logo{
      display: none;
    }
  }
  @media (min-width:992px) {
    border-top: 1px solid rgba(#8e909d, 0.25);
    padding-top: 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .footer-logo{
      flex:0 0 30%;
    }
    .footer-contacts{
      flex:0 0 70%;
    }
  }
}
.footer-contacts{
  margin-bottom: 0;
  @media (max-width:991px) {
    border-top: 1px solid rgba(#8e909d, 0.25);
    padding-top: 28px;
  }
}

.fancybox-bg {
  background: rgba(#0e1022,.8);
}
.compensate-for-scrollbar {
  margin-right: 0px !important;
  //padding-right: 17px;
}
.fancybox-close-small{

  width: 37px;
  height: 37px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 38px;
    height: 38px;
    background: url("../images/close.png") no-repeat center;
    border-width: 0;
    background-color: transparent;
    transition: background-color .25s;
    box-sizing: border-box;
    z-index: 99;
  }
  &:hover{
    &:after{
      background: url("../images/close.png") no-repeat center;
    }
  }

}
.modal-privacy{
  max-width: 970px;
  background: $white;
  padding: 50px 50px 40px;
  display: none;
  .privacy__title{
    font-size: 36px;
    line-height: 42px;
    font-weight: 500;
    color:$green;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .privacy__text{
    font-size: 16px;
    line-height: 1.2;
    color:$black-lite;
    padding: 0;
    margin: 0 0 15px;
  }
  .privacy__subtitle{
    font-size: 24px;
    font-weight: 500;
    color:$black-lite;
  }
  .fancybox-close-small{
    top:15px;
    right:15px;
    background: url("../images/close-green.png") no-repeat center;
  }
  @media (max-width:569px) {
    padding: 31px 20px 40px;
  }
}
.modal-resume{
  max-width: 570px;
  background: #161228;
  padding: 50px 50px 40px;
  display: none;
  .app-form3{
    padding-bottom: 0;
    .app-form__h3{
      text-align: center;
    }
    .app-form__fieldset{
      margin-bottom: 20px !important;
    }
    .app-form__personal{
      margin-top: 10px !important;
    }
  }
  .fancybox-close-small{
    top:15px;
    right:15px;
  }
  @media (max-width:569px) {
    padding: 31px 20px 40px;
  }

}
.modal-thank{
  display: none;
  max-width: 570px;
  background: #161228;
  color:$white;
  text-align: center;
  padding: 54px 20px 57px;
  &__title{
    font-size: 24px;
    line-height: 36px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 28px;
  }
  &__text{
    font-size: 16px;
  }
  .fancybox-close-small{
    top:15px;
    right:15px;
  }
}
.modal-kd{
  max-width: 970px;
  display: none;
  padding: 0;
  .modal-kd__header{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 299px;
    position: relative;
    padding: 0 60px;
    .title{
      position: absolute;
      bottom: 36px;
      font-size: 30px;
      line-height: 42px;
      font-weight: 900;
      color:$white;
    }
  }
  .modal-kd__content{
    display: flex;
    flex-wrap: wrap;
    .modal-kd__form{
      background: #161228;
      flex:0 0 38%;
      padding: 52px 40px 38px 60px;
    }
    .modal-kd__detail{
      color:$black;
      padding: 55px 100px 0 71px;
      flex:0 0 62%;
      background: $white;
      .title{
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        color:$black;
        margin-bottom: 32px;
      }
      .text{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 26px;
      }
      .list{
        font-size: 16px;
        line-height: 24px;

        li{
          margin-bottom: 26px;
          position: relative;
          &:before{
            content:"";
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $green;
            left: -15px;
            top:11px;
          }
        }
      }
    }
    .app-form3{
      padding-bottom: 0;
      .app-form__fieldset{
        margin-bottom: 20px !important;
      }
      .app-form__personal{
        margin-top: 0px !important;
        margin-bottom: 20px !important;
      }
      .app-form__callback{
        text-align: center;
        a{
          display: block;
        }
      }
    }
  }
  .fancybox-close-small{
    top:35px;
    right:35px;
  }
  @media (max-width:767px) {
    .modal-kd__header{
      padding: 0 20px;

      .title{
        font-size: 22px;
        line-height: 32px;
      }
    }
    .modal-kd__content{

      .modal-kd__form{
        flex:0 0 100%;
        order:2;
      }
      .modal-kd__detail{
        flex:0 0 100%;

      }
    }
    .fancybox-close-small{
      top:10px;
      right:10px;
    }
  }
  @media (max-width: 991px){
    .modal-kd__content{
      .modal-kd__form{

        padding: 35px 20px;
      }
      .modal-kd__detail{

        padding: 35px 20px 7px 40px;
      }
    }
  }
  @media (max-width:1023px) {
    .fancybox-close-small{
      background: #161228;
      &:after {
        width: 28px;
        height: 28px;
        top:4px;
        right: 4px;
      }
      &:hover{
        &:after{

        }
      }
    }


  }
}
.modal-case{
  .modal-kd__header{
    padding: 0;
    overflow-x:hidden ;
    .title{
      width: 100%;
      padding: 4px 60px 5px;
      bottom: 0px;
      line-height: 38px;
      span{
        position: relative;
        z-index: 2;
      }
      &:before{
        content: "";
        position: absolute;
        background: rgba(0,0,0,.5);
        z-index: 1;
        width: 120%;
        left: -10%;
        height: 100%;
        bottom: 0px;
      }
    }
  }
  @media (max-width:767px){
    .modal-kd__header{
      .title{
        padding: 4px 20px 5px;
        line-height: 27px;
      }
    }
  }
}
.modal-case1{
  .modal-kd__header{
    img{
      display: none;
    }
  }
  @media (max-width:767px) {
    max-width: 320px !important;
    .modal-kd__header{
      background: none !important;
      height: 200px;
      img{
        display: block;
      }
    }
  }
}
//fancybox
.fancybox-bg{
  background: rgba(0,0,0,0.95);
}


.parallax-section {

  @media (min-width:992px) {
    background-attachment: fixed !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

body.fancybox-active {
  overflow: hidden !important;
}



@media (max-width:1499px) {
 .my-paroller{
   display: none;
 }
}
@media (min-width:1500px) {
  .my-paroller{
    display: block;
  }
}
.gift-right{
  position: absolute;
  z-index: 1;
  right:97px;
}
.gift-left{
  position: absolute;
  z-index: 1;
  left:-290px;
  top:0;
}
.gift-rightCenter{
  position: absolute;
  z-index: 1;
  right:35px;
  bottom:-233px;
}

