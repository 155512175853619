
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.eot');
  src: local('../fonts/ProximaNova/ProximaNovaThin/Proxima Nova Thin'), local('ProximaNovaT-Thin'),
  url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.eot');
  src: local('../fonts/ProximaNova/ProximaNovaRegular/Proxima Nova Regular'), local('ProximaNova-Regular'),
  url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.eot');
  src: local('../fonts/ProximaNova/ProximaNovaLight/Proxima Nova Light'), local('ProximaNova-Light'),
  url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.eot');
  src: local('../fonts/ProximaNova/ProximaNovaBold/Proxima Nova Bold'), local('ProximaNova-Bold'),
  url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.eot');
  src: local('../fonts/ProximaNova/ProximaNovaSemibold/Proxima Nova Semibold'), local('ProximaNova-Semibold'),
  url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Extrabld';
  src: url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.eot');
  src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
  url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.eot');
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
  url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}






//normal italic
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Italic.eot');
  src: local('Gotham Pro Italic'), local('GothamPro-Italic'),
  url('../fonts/GothamPro/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-Italic.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
//BlackItalic
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-BlackItalic.eot');
  src: local('Gotham Pro Black Italic'), local('GothamPro-BlackItalic'),
  url('../fonts/GothamPro/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-BlackItalic.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
//bold italic
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-BoldItalic.eot');
  src: local('Gotham Pro Bold Italic'), local('GothamPro-BoldItalic'),
  url('../fonts/GothamPro/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-BoldItalic.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

//LightItalic
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-LightItalic.eot');
  src: local('Gotham Pro Light Italic'), local('GothamPro-LightItalic'),
  url('../fonts/GothamPro/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-LightItalic.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
//MediumItalic
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-MediumItalic.eot');
  src: local('Gotham Pro Medium Italic'), local('GothamPro-MediumItalic'),
  url('../fonts/GothamPro/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-MediumItalic.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
//bold Narrow
@font-face {
  font-family: 'Gotham Pro Narrow';
  src: url('../fonts/GothamPro/GothamProNarrow-Bold.eot');
  src: local('Gotham Pro Narrow Bold'), local('GothamProNarrow-Bold'),
  url('../fonts/GothamPro/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProNarrow-Bold.woff') format('woff'),
  url('../fonts/GothamPro/GothamProNarrow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
//medium Narrow 500
@font-face {
  font-family: 'Gotham Pro Narrow';
  src: url('../fonts/GothamPro/GothamProNarrow-Medium.eot');
  src: local('Gotham Pro Narrow Medium'), local('GothamProNarrow-Medium'),
  url('../fonts/GothamPro/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProNarrow-Medium.woff') format('woff'),
  url('../fonts/GothamPro/GothamProNarrow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

//light 300
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Light.eot');
  src: local('Gotham Pro Light'), local('GothamPro-Light'),
  url('../fonts/GothamPro/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-Light.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
//normal
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
  url('../fonts/GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
//medium 500
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
  url('../fonts/GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-Medium.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
//Bold
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Bold.eot');
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
  url('../fonts/GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-Bold.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
//black 900
@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Black.eot');
  src: local('Gotham Pro Black'), local('GothamPro-Black'),
  url('../fonts/GothamPro/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamPro-Black.woff') format('woff'),
  url('../fonts/GothamPro/GothamPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=cyrillic,cyrillic-ext,latin-ext');
$ProximaNova:'Proxima Nova';
$GothamPro:'Gotham Pro';
$PTSans:'PT Sans', sans-serif;




