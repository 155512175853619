.slick-slider {
  padding-bottom: 0px;
  &.slick-dotted {
    margin-bottom: 0px;
  }
  .slick-dots {
    bottom: 0px;
    li {
      height: 22px;
      width: 22px;
      button {
        height: 22px;
        width: 22px;
        &:before {
          background: $white;
          border: 1px solid transparent;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          opacity: 1;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

      }
      &.slick-active button:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        margin: auto;
        width: 22px;
        height: 22px;
        background: transparent;
        border: 1px solid $white;
        border-radius: 50%;
      }
    }
  }
  .slick-arrow {
    &:hover {
      cursor: pointer;
    }
  }
  .prevArrow {
    width: 30px;
    height: 103px;
    background: url("../images/arrowPrev.png") no-repeat;
    z-index: 1;
    position: absolute;
    top: 44%;
    left: 20px;
  }
  .nextArrow {
    width: 30px;
    height: 103px;
    background: url("../images/arrowNext.png") no-repeat;
    z-index: 1;
    position: absolute;
    top: 44%;
    right: 20px;
  }
}








