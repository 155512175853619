.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}
.checkbox + label {
  position: relative;
  padding: 0 0 0 33px;
  cursor: pointer;
}
.checkbox + label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 23px;
  height: 23px;
  background: $green;
  transition: .2s;
}
.checkbox + label:after {
  content: '';
  position: absolute;
  top: 7px;
  left: 5px;
  width: 14px;
  height: 11px;
  background: url("../images/icon-gal.png") no-repeat;
  transition: .2s;
  opacity: 0;
}
.checkbox:checked + label:before {
  background: $green;
}
.checkbox:checked + label:after {
  opacity: 1;
}
